
.btn-grad51 {
    background-image: linear-gradient(to right, #0662a3 0%, #434340  51%, #926215  100%);
    margin: 0px;
    padding: 7px 20px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 12px;
    display: block;
  }
  
  .btn-grad51:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .draftsmanship{
    background-color: #c9e3ed;
  }