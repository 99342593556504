.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



a {
  text-decoration: none !important;
  
}

.colorlogo h5{
  color: #0c6d9a;
}

.backgroundc{
  background-color: #0c6d9a;
}

.textcolor{
  background-color: #0c6d9a;
}

.container{
  font-family: "Nanum Gothic", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.newbuton{
  background-color: #16445a; /* Green */
  border: none;
  color: white;
  padding: 7px 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 11px;
}

.color h1{
  color: #0c6d9a;
}

.buttonform{
  background-color: #16445a; /* Green */
  border: none;
  color: white;
  padding: 7px 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 11px;

}